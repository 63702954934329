<script>
import { get } from '@rails/request.js';

export default {
  name: 'TimetableMixin',
  methods: {
    isDateOnSameDay: function(dateA, dateB) {
      return dateA.getFullYear() === dateB.getFullYear() &&
             dateA.getMonth() === dateB.getMonth() &&
             dateA.getDate() === dateB.getDate()
    },
    onDateChanged: function(dateInfo) {
      dateInfo.end.setSeconds(dateInfo.end.getSeconds() - 1)
      const calendarApi = this.$refs.calendar.getApi()

      var startEventDate = new Date(this.eventStartDate)
      if (this.isDateOnSameDay(dateInfo.start, startEventDate)) {
        const slotMinTime = `${('0'+Math.max(startEventDate.getHours()-1, 0)).slice(-2)}:${('0'+startEventDate.getMinutes()).slice(-2)}:${('0'+startEventDate.getSeconds()).slice(-2)}`
        calendarApi.setOption('slotMinTime', slotMinTime)
      } else {
        calendarApi.setOption('slotMinTime', "00:00:00")
      }

      var endEventDate = new Date(this.eventEndDate)
      if (this.isDateOnSameDay(dateInfo.end, endEventDate)) {
        const slotMaxTime = `${('0'+Math.min(endEventDate.getHours()+1, 24)).slice(-2)}:${('0'+endEventDate.getMinutes()).slice(-2)}:${('0'+endEventDate.getSeconds()).slice(-2)}`
        calendarApi.setOption('slotMaxTime', slotMaxTime)
      } else {
        calendarApi.setOption('slotMaxTime', "24:00:00")
      }
    },
    toggleSlotDuration: function() {
      const calendarApi = this.$refs.calendar.getApi()
      var newSlotDuration = calendarApi.getOption('slotDuration') == '00:15:00' ? '00:30:00' : '00:15:00';
      calendarApi.setOption('slotDuration', newSlotDuration);
    },
    onClick: async function(eventClickInfo) {
      eventClickInfo.jsEvent.preventDefault();
    },
    removeShift: async function(shiftId) {
      const shift = this.$refs.calendar.getApi().getEventById(shiftId)
      if (shift) {
        shift.remove()
      }
    },
    refreshShiftsOfVolunteer: async function(volunteerId) {
      const calendar = this.$refs.calendar.getApi()
      const response = await get(this.shiftsUrl, { query: {
        "start": calendar.view.currentStart.toISOString(),
        "end": calendar.view.currentEnd.toISOString(),
        "volunteer_id": volunteerId,
      }})
      if (!response.ok) {
        return
      }

      const content = await response.json
      for (const shift of content) {
        const event = this.$refs.calendar.getApi().getEventById(shift.id)
        if (event) {
          event.remove()
        }
        calendar.addEvent(shift)
      }
    },
  }
}
</script>
